<template>
	<b-container class="container">
		<w-loadable :items="path" />
		<b-row v-if="path" class="mb-4">
			<b-col xl="9" lg="12">
				<b-row align-v="center">
					<b-col cols="auto">
						<h1 class="mb-2">{{ path.name }}</h1>
					</b-col>
					<b-col class="mr-auto" cols="auto">
						<a-path-badge-path />
					</b-col>
					<b-col class="align-self-end" cols="auto">
						<z-toggle-starred :model="'path'" :id="path.id"
							:starred="path.is_starred_by_active_user"
							starText="Pridať medzi Moje kurzy"
							unstarText="Odstrániť z Mojich kurzov"
							starredText="Séria bola pridaná medzi Moje kurzy!"
							unstarredText="Séria bola odstránená z Mojich kurzov a sérií!"
						/>
					</b-col>
				</b-row>
			</b-col>
			<b-col xl="3" lg="12"></b-col>
		</b-row>
		<b-row v-if="path">
			<b-col xl="8" lg="12">
				<!-- Teoreticky by sa dal pouzit a-lesson-hero-object, ale s vacsimi upravami -->
				<b-img class="path-thumbnail mb-4 mb-xl-5" :src="path.image.path" alt="Thumbnail" fluid-grow></b-img>
				<div class="path-content mb-4 mb-xl-5" v-html="renderMd(path.content)"></div>
			</b-col>
			<b-col xl="4" lg="12">
				<a-path-explanation class="mb-4" />
				<a-path-benefits :benefits="path.benefits" />
			</b-col>
			<b-col xl="9" lg="12">
				<div class="path-results mt-4 mb-3">
					<h3 class="--rule mb-4">
						{{ isAdmin ? 'V tejto Sérii sa dozviete:' : 'V tejto Sérii sa dozvieš:' }}
					</h3>
					<b-row>
						<b-col cols="auto" class="pr-2 pr-sm-3">
							<div class="z-icon-wrapper -middle mr-4 w-background-primary">
								<svg-bookmark-notebook-icon class="w-color-white" />
							</div>
						</b-col>
						<b-col>
							<div class="path-results__text font-weight-bold" v-html="renderMd(this.path.results)"></div>
						</b-col>
					</b-row>
				</div>
				<a-path-lessons :path="path" />
				<section v-if="path.partners && path.partners.length">
					<a-lesson-tabular-content-detail-partners :partners="path.partners" />
				</section>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import MarkdownIt from 'markdown-it'
import { mapGetters } from 'vuex'

const api = {
	path: (slug) => wAxios.get_auth_data(`/v1/paths/${slug}`)
}

export default {
	data() {
		return {
			path: null,
			md: null
		}
	},

	components: {
		'z-toggle-starred': 												() => import('@/plugins/lib@_component/toggle-starred/z-toggle-starred.vue'),
		'a-path-badge-path': 												() => import('./a-path-badge-path.vue'),
		'a-path-lessons': 													() => import('./a-path-lessons.vue'),
		'a-path-explanation': 											() => import('./a-path-explanation.vue'),
		'a-path-benefits': 													() => import('./a-path-benefits.vue'),
		'a-lesson-tabular-content-detail-partners': () => import('@/plugins/lib@lesson/lesson-tabular/a-lesson-tabular-content-detail-partners.vue'),
		'svg-bookmark-notebook-icon': 							() => import('@/plugins/appzmudri/_theme/icon/bookmark-notebook.svg?inline'),
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user && this.user.profile && this.user.profile.type == 'teacher'
		}
	},

	async created() {
		this.$wEvent.userLog('path.view', {path_slug: this.$route.params.pathSlug})
		this.md = new MarkdownIt()
		await this._loadPath()
	},

	methods: {
		renderMd(input) {
			return this.md.render(input)
		},

		async _loadPath() {
			try {
				const path = await api.path(this.$route.params.pathSlug)
				this.path = path

				// Process benefits
				this.path.benefits = this._processBenefits(this.path.benefits)
			} catch (error) {
				this.$wToast.error(error)
			}
		},
		_processBenefits(benefitString) {
			let benefitArray = benefitString.split('\n').filter(benefit => {
				benefit = benefit.trim()
				return benefit.length
			})

			const benefits = []
			for (let i = 0; i < benefitArray.length; i += 2) {
				const benefit = {}
				benefit.type = benefitArray[i]
				benefit.description = benefitArray[i + 1]
				benefits.push(benefit)
			}

			return benefits
		}
	}
}
</script>

<style lang="scss" scoped>
h1 {
	line-height: 1;
	font-size: 1.5rem;
}

.path-thumbnail {
	border-radius: 12px;
}

.path-content,
.path-results__text {
	margin-right: 1rem;
}

.path-results__text::v-deep ul {
	padding-inline-start: 1rem;
}

/* TODO: Discuss universal change */
.z-icon-wrapper {
 /* TODO: Refactor, DRY */
	&.--middle {
		flex: 0 0 53px;
		width: 53px;
		height: 53px;
	}

	/* REVIEW: Exceptions from the current rules. Better rules are needed. */
	svg {
		min-height: 30px;
	}
}

section, ::v-deep section {
	padding: 2rem 0;

	.z-icon-wrapper {
		margin-right: 1.5rem;
	}
}
</style>